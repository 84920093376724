
import dashboard_icon from "../assets/svg-icons/ic_dashboard.svg"
import product_icon from "../assets/svg-icons/ic_cart.svg"
import ic_ecommerce from "../assets/svg-icons/ic_ecommerce.svg"
import ic_user from "../assets/svg-icons/ic_user.svg"
import supply_chain from "../assets/svg-icons/supply_chain.png"
import dot from "../assets/svg-icons/dot.png"
import settings from "../assets/svg-icons/settings.png"
import graph from "../assets/svg-icons/graph.png"
import report from "../assets/svg-icons/report.png"
import administrator from "../assets/svg-icons/administrator.png"

export default {
    namespaced: true,

    state: () => ({
        menuItems: [
            {
                name: "Dashboard",
                // icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                //             <path opacity="0.4" d="M21.1808 16.9703C20.8971 17.6255 20.2225 18 19.5086 18H14.8154C14.8462 17.9145 14.8735 17.8269 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.93642 16.7899 8.97782 17.4291 9.18451 18H4.49141C3.77747 18 3.10288 17.6255 2.81918 16.9703C2.29212 15.7533 2 14.4108 2 13C2 7.47715 6.47715 3 12 3C17.5229 3 22 7.47715 22 13C22 14.4108 21.7079 15.7533 21.1808 16.9703Z" fill="#637381"/>
                //             <path d="M14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.6742 17.7856 9.62375 19.459 11.2238 19.8953C12.8238 20.3315 14.4684 19.3654 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516Z" fill="#637381"/>
                //         </svg>`,
                icon: dashboard_icon,
                icon_type: 'svg',
                link: "/",
                to: "/",
                color: "",
                show: false,
                locked: true,
                id: "difw9l",
            },
            {
                name: "Purchase",
                icon: ic_ecommerce,
                icon_type: 'svg',
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Purchase Entry",
                        link: "/purchase",
                        color: "",
                        id: "n79ccl",
                        // icon: "mdi-cart-arrow-down",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Purchase Return",
                        link: "/purchase-return",
                        color: "",
                        id: "vntck1",
                        // icon: "mdi-cart-arrow-down",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Purchase Record",
                        link: "/purchases",
                        color: "",
                        id: "vntck",
                        // icon: "mdi-cart-arrow-down",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Reports",
                        // icon: "mdi-clipboard-plus-outline",
                        icon: "ti ti-chart-dots-2",
                        // link: "/",
                        color: "blue",
                        locked: true,
                        children: [
                            {
                                name: "Invoice",
                                link: "/purinvoice",
                                color: "",
                                id: "k4ro3q",
                                // icon: "mdi-file",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Purchase Returns",
                                link: "/purchase-returns",
                                color: "orange",
                                id: "o5qp3ep",
                                // icon: "mdi-cart-arrow-down",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            // {
                            //     name: "Product Price List",
                            //     link: "/product-price",
                            //     color: "orange",
                            //     id: "o1qpepvq",
                            //     icon: "mdi-cart",
                            // },
                            // {
                            //     name: "Product Stock",
                            //     link: "/",
                            //     color: "orange",
                            //     id: "cio9r39",
                            //     icon: "mdi-cart",
                            // },
                        ],
                        id: "88yvfon",
                    },
                ],
                id: "oh8w8s",
            },
            {
                name: "Sales",
                icon: product_icon,
                icon_type: 'svg',
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Sale Entry",
                        link: "/sale",
                        to: "/sale",
                        color: "",
                        id: "nbpu5",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    // {
                    //     name: "Pos Terminal",
                    //     link: "/pos",
                    //     to: "/pos",
                    //     color: "",
                    //     id: "nba8ca35",
                    //     icon: dot,
                    //     icon_type: 'svg',
                    // },
                    {
                        name: "Sale Return",
                        link: "/sale-return",
                        color: "",
                        id: "vba2e4",
                        // icon: "mdi-cart",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Sale Record",
                        link: "/sales",
                        color: "",
                        id: "efnnlyk",
                        // icon: "mdi-cart",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Product Stock",
                        link: "/stock",
                        color: "",
                        id: "ef1nnlyk",
                        // icon: "mdi-basket",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Reports",
                        // icon: "mdi-clipboard-plus-outline",
                        icon: "ti ti-chart-dots-2",
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Invoice",
                                link: "/saleinvoice",
                                color: "",
                                id: "k4roq",
                                // icon: "mdi-cart",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Sale Returns",
                                link: "/sale-returns",
                                color: "",
                                id: "o5qpep",
                                // icon: "mdi-cart",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Product Price List",
                                link: "/products",
                                color: "",
                                id: "o5qpepvq",
                                // icon: "mdi-cart",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            // {
                            //     name: "Product Stock",
                            //     link: "/",
                            //     color: "blue",
                            //     id: "cio9r9",
                            //     icon: "mdi-cart",
                            // },
                        ],
                        id: "95yvfon",
                    },
                ],
                id: "qowpep",
            },
            {
                name: "Products",
                icon: "ti ti-brand-producthunt",
                // link: "/",
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Product Entry",
                        link: "/product",
                        color: "",
                        id: "v20cz",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Product List",
                        link: "/product-list",
                        color: "",
                        id: "v20c2z",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Damage Entry",
                        link: "/damage",
                        color: "",
                        id: "ef1nclyk",
                        // icon: "mdi-delete-variant",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Damage Record",
                        link: "/damages",
                        color: "",
                        id: "4441lyk",
                        // icon: "mdi-format-line-weight",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Low stock report",
                        link: "/low-stock-report",
                        color: "",
                        id: "4491lyk",
                        // icon: "mdi-format-line-weight",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Product Ledger",
                        link: "/product-ledger",
                        color: "",
                        id: "i8sznoh",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Categories",
                        link: "/categories",
                        color: "",
                        id: "2tdpco",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Units",
                        link: "/units",
                        color: "",
                        id: "2tdcpo",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Brands",
                        link: "/brands",
                        color: "",
                        id: "26dpco",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                ],
                id: "6cegai",
            },
            {
                name: "Customers",
                // icon: "mdi-factory",
                icon: ic_user,
                icon_type: "svg",
                // link: "/",
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Customer Entry",
                        link: "/customer",
                        color: "",
                        id: "qio1ie",
                        // icon: "mdi-factory",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Customer List",
                        link: "/customers",
                        color: "",
                        id: "dcp45",
                        // icon: "mdi-factory",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Customer Payment",
                        link: "/customer-payment",
                        color: "",
                        id: "dcp452s",
                        // icon: "mdi-factory",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Reports",
                        // icon: "mdi-factory",
                        icon: "ti ti-chart-dots-2",
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Customer Due List",
                                link: "/customer-due",
                                color: "",
                                id: "z1neiq",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Customer Ledger",
                                link: "/customer-ledger",
                                color: "",
                                id: "rtjjfh",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Customer Payments",
                                link: "/customer-payments",
                                color: "",
                                id: "hjmtza",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "ypaein",
                    },
                ],
                id: "nfkg6b",
            },
            {
                name: "Suppliers",
                // icon: "mdi-account-tie-outline",
                icon: supply_chain,
                icon_type: "svg",
                // link: "/",
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Supplier Entry",
                        link: "/supplier",
                        color: "",
                        id: "3lshsm",
                        // icon: "mdi-account-tie-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Supplier List",
                        link: "/suppliers",
                        color: "",
                        id: "m5brd8",
                        // icon: "mdi-account-tie-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Supplier Payment",
                        link: "/supplier-payment",
                        color: "",
                        id: "w3h41b",
                        // icon: "mdi-account-tie-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Reports",
                        // icon: "mdi-account-tie-outline",
                        icon: "ti ti-chart-dots-2",
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Supplier Due List",
                                link: "/supplier-due",
                                color: "",
                                id: "k6bdg",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Supplier Ledger",
                                link: "/supplier-ledger",
                                color: "",
                                id: "fa6jfq",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Supplier Payments",
                                link: "/supplier-payments",
                                color: "",
                                id: "yk4rf",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "3on82",
                    },
                ],
                id: "vzedf4",
            },
            {
                name: "Accounts",
                icon: "mdi-cash-register",
                // link: "/",
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Cash Transaction",
                        link: "/cash-transaction",
                        color: "",
                        id: "0syqgq",
                        // icon: "mdi-currency-bdt",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Cash Trnasaction Record",
                        link: "/cash-transactions",
                        color: "",
                        id: "b5bbps",
                        // icon: "mdi-format-line-spacing",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Bank Transaction",
                        link: "/bank-transaction",
                        color: "",
                        id: "25gox8",
                        // icon: "mdi-bank",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Bank Transaction Record",
                        link: "/bank-transactions",
                        color: "",
                        id: "25g420x8",
                        // icon: "mdi-format-list-bulleted",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Cash View",
                        link: "/cash-view",
                        color: "",
                        id: "25g3bx8",
                        // icon: "mdi-cash",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Cash Ledger",
                        link: "/cash-ledger",
                        color: "",
                        id: "2gbx8",
                        // icon: "mdi-cash",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Bank Ledger",
                        link: "/bank-ledger",
                        color: "",
                        id: "2gcx8",
                        // icon: "mdi-cash",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Account Head",
                        icon: "mdi-account-tie-outline",
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Expense Account",
                                link: "/expenses",
                                color: "",
                                id: "k6bd5g",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type:'svg',
                            },
                            {
                                name: "Bank Account",
                                link: "/bank-account",
                                color: "",
                                id: "fa685fq",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type:'svg',
                            }
                        ],
                        id: "xy3ir",
                    },
                    {
                        name: "Cheque",
                        color: "",
                        icon: "mdi-cash-check",
                        locked: true,
                        id: 'xnxb652',
                        children: [
                            {
                                name: "Cheque Entry",
                                link: "/cheque",
                                color: "",
                                // icon: "mdi-card-plus-outline",
                                icon: dot,
                                icon_type:'svg',
                                id: 'caz3u43',
                            },
                            {
                                name: "Cheque List",
                                link: "/cheques",
                                color: "",
                                // icon: "mdi-card-text-outline",
                                icon: dot,
                                icon_type:'svg',
                                id: 'ni94e0',
                            },
                            {
                                name: "Reminder Cheque List",
                                link: "/cheque-reminder",
                                color: "",
                                // icon: "mdi-card-text",
                                icon: dot,
                                icon_type:'svg',
                                id: 'h5i852iq',
                            },
                            {
                                name: "Pending Cheque List",
                                link: "/pending-cheque",
                                color: "",
                                // icon: "mdi-card-bulleted-off",
                                icon: dot,
                                icon_type:'svg',
                                id: 'p056jkjd',
                            },
                            {
                                name: "Dishonoured Cheque List",
                                link: "/dishonoured-cheque",
                                color: "",
                                // icon: "mdi-card-bulleted-off-outline",
                                icon: dot,
                                icon_type:'svg',
                                id: 'jqxi978q',
                            },
                            {
                                name: "Paid Cheque List",
                                link: "/paid-cheque",
                                color: "",
                                // icon: "mdi-cash-check",
                                // icon: "ti ti-dots-circle-horizontal",
                                icon: dot,
                                icon_type: 'svg',
                                id: 'px7012d',
                            },
                        ]
                    },
                    {
                        name: "Bank To Bank",
                        color: "",
                        icon: "mdi-notebook",
                        locked: true,
                        id: 'xnxb672',
                        children: [
                            {
                                name: "B2B Transaction",
                                link: "/banktobank",
                                color: "",
                                // icon: "mdi-card-plus-outline",
                                icon: dot,
                                icon_type:'svg',
                                id: 'caz3u43',
                            },
                            {
                                name: "B2B Transaction List",
                                link: "/banktobanks",
                                color: "",
                                // icon: "mdi-card-text-outline",
                                icon: dot,
                                icon_type:'svg',
                                id: 'ni94e0',
                            },
                        
                        ]
                    },
                ],
                id: "y8en5k",
            },
            {
                name: "HR & Payroll",
                icon: "mdi-account-clock",
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Employee Entry",
                        link: "/employee",
                        color: "",
                        id: "5zak3n",
                        // icon: "mdi-account-clock",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Salary Generate",
                        link: "/salary-generate",
                        color: "",
                        id: "i7fpmp",
                        // icon: "mdi-account-clock",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Salary Payment",
                        link: "/salary-payment",
                        color: "",
                        id: "2ufl4",
                        // icon: "mdi-account-clock",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    
                    {
                        name: "Settings",
                        locked: true,
                        children: [
                            {
                                name: "Departments",
                                link: "/departments",
                                color: "",
                                id: "4q3aob",
                                // icon: "mdi-account-clock",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Designations",
                                link: "/designations",
                                color: "",
                                id: "9vtlb",
                                // icon: "mdi-account-clock",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Month Entry",
                                link: "/months",
                                color: "",
                                id: "1owb8f",
                                // icon: "mdi-account-clock",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        color: "",
                        id: "twytv",
                        // icon: "mdi-account-clock",
                        icon: settings,
                        icon_type: 'svg',
                    },
                    {
                        name: "Reports",
                        locked: true,
                        children: [
                            {
                                name: "Employee List",
                                link: "/employees",
                                color: "",
                                id: "vqyzr",
                                // icon: "mdi-account-clock",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Salary Sheet",
                                link: "/salary-sheet",
                                color: "",
                                id: "2uf3jl4",
                                // icon: "mdi-google-spreadsheet",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Salary Payments",
                                link: "/salary-payments",
                                color: "",
                                id: "2uf1l4",
                                // icon: "mdi-account-clock",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        color: "",
                        id: "tw8ytv",
                        // icon: "mdi-account-clock",
                        icon: report,
                        icon_type: 'svg',
                    },
                ],
                id: "cdnbkh",
            },
            {
                name: "Administration",
                // icon: "mdi-account-tie",
                icon: administrator,
                icon_type: 'svg',
                // link: "/",
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Create User",
                        link: "/user",
                        color: "",
                        id: "4rlowt",
                        // icon: "mdi-account-plus",
                        icon: dot,
                        icon_type:'svg',
                    },
                    {
                        name: "Company Profile",
                        link: "/company-profile",
                        color: "",
                        id: "aypq3",
                        // icon: "mdi-information",
                        icon: dot,
                        icon_type:'svg',
                    },
                    {
                        name: "Areas",
                        link: "/areas",
                        color: "",
                        id: "4eydyp",
                        // icon: "mdi-map-marker-outline",
                        icon: dot,
                        icon_type:'svg',
                    },
                    // {
                    //     name: "Branches",
                    //     link: "/branches",
                    //     color: "",
                    //     id: "4eyd4p",
                    //     // icon: "mdi-map-marker-outline",
                    //     // icon: "ti ti-git-branch",
                    //     icon: dot,
                    //     icon_type:'svg',
                    // },
                    // {
                    //     name: "Customer Type",
                    //     link: "/customer-type",
                    //     color: "amber",
                    //     id: "4eyyyp",
                    //     icon: "mdi-car",
                    // },
                    // {
                    //     name: "Customer Points",
                    //     link: "/custome-point",
                    //     color: "amber",
                    //     id: "4et1yp",
                    //     icon: "mdi-car",
                    // },
                ],
                id: "fpebel",
            },
            {
                name: "Reports",
                // icon: "mdi-clipboard-plus-outline",
                icon: report,
                icon_type: 'svg',
                color: "",
                show: false,
                locked: true,
                children: [
                    {
                        name: "Product List",
                        link: "/product-list",
                        color: "",
                        id: "v20c2z",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Product Stock",
                        link: "/stock",
                        color: "",
                        id: "ef1nnlyk",
                        // icon: "mdi-basket",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Product Ledger",
                        link: "/product-ledger",
                        color: "",
                        id: "i8sznoh",
                        // icon: "mdi-alpha-p-circle-outline",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Employee List",
                        link: "/employees",
                        color: "",
                        id: "vqyzr",
                        // icon: "mdi-account-clock",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Salary Sheet",
                        link: "/salary-sheet",
                        color: "",
                        id: "2uf3jl4",
                        // icon: "mdi-google-spreadsheet",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Salary Payments",
                        link: "/salary-payments",
                        color: "",
                        id: "2uf1l4",
                        // icon: "mdi-account-clock",
                        icon: dot,
                        icon_type: 'svg',
                    },
                    {
                        name: "Account Reports",
                        // icon: "mdi-currency-bdt",
                        icon: 'ti ti-chart-dots-2',
                        // icon_type: 'svg',
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Cash Trnasaction Record",
                                link: "/cash-transactions",
                                color: "pink",
                                id: "b5bbps",
                                // icon: "mdi-format-line-spacing",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Bank Transaction Record",
                                link: "/bank-transactions",
                                color: "pink",
                                id: "25g420x8",
                                // icon: "mdi-format-list-bulleted",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Cash View",
                                link: "/cash-view",
                                color: "pink",
                                id: "25g3bx8",
                                // icon: "mdi-cash",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Cash Ledger",
                                link: "/cash-ledger",
                                color: "pink",
                                id: "2p3bx8",
                                // icon: "mdi-cash",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Bank Ledger",
                                link: "/bank-ledger",
                                color: "pink",
                                id: "2xn18",
                                // icon: "mdi-cash",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "yp0aeidn",
                    },
                    {
                        name: "Customer Reports",
                        // icon: "mdi-factory",
                        icon: report,
                        icon_type: 'svg',
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Customer List",
                                link: "/customers",
                                color: "",
                                id: "dcp45",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Customer Due List",
                                link: "/customer-due",
                                color: "",
                                id: "z1neiq",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Customer Ledger",
                                link: "/customer-ledger",
                                color: "",
                                id: "rtjjfh",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Customer Payments",
                                link: "/customer-payments",
                                color: "",
                                id: "hjmtza",
                                // icon: "mdi-factory",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "yp0aein",
                    },
                    {
                        name: "Supplier Reports",
                        // icon: "mdi-account-tie-outline",
                        icon: "ti ti-report",
                        // link: "/",
                        color: "",
                        locked: true,
                        children: [
                            {
                                name: "Supplier List",
                                link: "/suppliers",
                                color: "",
                                id: "m5brd8",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Supplier Due List",
                                link: "/supplier-due",
                                color: "",
                                id: "k6bdg",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Supplier Ledger",
                                link: "/supplier-ledger",
                                color: "",
                                id: "fa6jfq",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Supplier Payments",
                                link: "/supplier-payments",
                                color: "",
                                id: "yk4rf",
                                // icon: "mdi-account-tie-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "3o47n82",
                    },
                    {
                        name: "Purchase Reports",
                        locked: true,
                        color: "",
                        // icon: "mdi-cart-arrow-down",
                        icon: "ti ti-chart-infographic",
                        children: [
                            {
                                name: "Purchase Invoice",
                                link: "/purinvoice",
                                color: "",
                                id: "k4ro3q",
                                // icon: "mdi-file",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Purchase Record",
                                link: "/purchases",
                                color: "",
                                id: "vntck",
                                // icon: "mdi-cart-arrow-down",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Purchase Returns",
                                link: "/purchase-returns",
                                color: "",
                                id: "o5qp3ep",
                                // icon: "mdi-cart",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Damage Record",
                                link: "/damages",
                                color: "",
                                id: "4441lyk",
                                // icon: "mdi-format-line-weight",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "twytv",
                    },
                    {
                        name: "Sale Reports",
                        locked: true,
                        color: "",
                        // icon: "mdi-cart-arrow-down",
                        icon: "ti ti-report-money",
                        children: [
                            {
                                name: "Sale Invoice",
                                link: "/saleinvoice",
                                color: "",
                                id: "k4roq",
                                // icon: "mdi-file",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Sale Record",
                                link: "/sales",
                                color: "",
                                id: "efnnlyk",
                                // icon: "mdi-cart",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Sale Returns",
                                link: "/sale-returns",
                                color: "",
                                id: "o5qpep",
                                // icon: "mdi-server",
                                icon: dot,
                                icon_type: 'svg',
                            },
                            {
                                name: "Product Price List",
                                link: "/products",
                                color: "",
                                id: "o5qpepvq",
                                // icon: "mdi-clipboard-list-outline",
                                icon: dot,
                                icon_type: 'svg',
                            },
                        ],
                        id: "twy4tv",
                    },
                    
                    
                ],
                id: "cdn98kh",
            },
            // {
            // 	name: "Logout",
            // 	icon: "mdi-logout",
            // 	link: "/login",
            // 	color: "amber",
            // 	show: false,
            // 	locked: true,
            // 	id: 'lxectd'
            // }
        ],
    }),

    getters: {
        menuItems(state) {
            return state.menuItems;
        } 
    },

    mutations: {
        setMentItem(state, menuItem) {
            state.menuItems = menuItem
        }
    },

    actions: {

    }
}